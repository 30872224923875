import * as BABYLON from "@babylonjs/core/Legacy/legacy";

function DeviceAPI() {

}

DeviceAPI.iconObserver = null

DeviceAPI.isGetDevicePos = false
// 设备接口数据
DeviceAPI.deviceInterfaceData = {
    "manage": null,
    "broadcast": null,
    "light": null,
    "monitor": null,
    "waterTank": null,
    "powerBox": null,
    "coMonitor": null,
    "waterMonitor": null,
    "Fan": null,
    "lift": null,
    "airConditioning": null,
    "dynamo": null,
}
// 设备模型meshName对应id
DeviceAPI.deviceCoreInfo = {
    "broadcast": [
        {deviceName: "7号楼-1F-广播-node8", deviceID: "aaa-111"},
        {deviceName: "7号楼-1F-广播-node16", deviceID: "aaa-222"},
        {deviceName: "7号楼-2F-广播-node8", deviceID: "aaa-333"},
        {deviceName: "7号楼-3F-广播-node8", deviceID: "aaa-444"},
        {deviceName: "7号楼-3F-广播-node18", deviceID: "aaa-555"},
    ],
    "monitor": [
        {deviceName: "7号楼-1F-监控-node12", deviceID: "bbb-111"},
        {deviceName: "7号楼-1F-监控-node6", deviceID: "bbb-222"},
        {deviceName: "7号楼-2F-监控-node22", deviceID: "bbb-333"},
        {deviceName: "7号楼-3F-监控-node27", deviceID: "bbb-444"},
        {deviceName: "7号楼-3F-监控-node49", deviceID: "bbb-555"},
    ],
    "manage": [],
    "light": [],
    "waterTank": [],
    "powerBox": [],
    "coMonitor": [],
    "waterMonitor": [],
    "Fan": [],
    "lift": [],
    "airConditioning": [],
    "dynamo": [],
}

// 1、获取设备接口数据
DeviceAPI.getDeviceData = function () {
    this.deviceInterfaceData.broadcast = [
        {DEVICE_ID: "aaa-111", DEVICE_NAME: "广播1", DEVICE_STATUS: false, DEVICE_VALUE: 50},
        {DEVICE_ID: "aaa-222", DEVICE_NAME: "广播2", DEVICE_STATUS: true, DEVICE_VALUE: 50},
        {DEVICE_ID: "aaa-333", DEVICE_NAME: "广播3", DEVICE_STATUS: true, DEVICE_VALUE: 50},
        {DEVICE_ID: "aaa-444", DEVICE_NAME: "广播4", DEVICE_STATUS: false, DEVICE_VALUE: 50}
    ]
    this.deviceInterfaceData.monitor = [
        {DEVICE_ID: "bbb-111", DEVICE_NAME: "监控1"},
        {DEVICE_ID: "bbb-222", DEVICE_NAME: "监控2"},
        {DEVICE_ID: "bbb-333", DEVICE_NAME: "监控3"},
        {DEVICE_ID: "bbb-444", DEVICE_NAME: "监控4"}
    ]
    // 设备在场景中的名字
    let deviceNameArr = []
    // 接口里的模型id与场景中的模型对应关系
    for (let deviceType in DeviceAPI.deviceInterfaceData) {
        // 该设备类型为空
        if (!DeviceAPI.deviceInterfaceData[deviceType]) {
            continue
        }
        DeviceAPI.deviceInterfaceData[deviceType].forEach(dev => {
            let device = DeviceAPI.deviceCoreInfo[deviceType].find(item => item.deviceID === dev["DEVICE_ID"])
            if (device) {
                deviceNameArr.push(device.deviceName)
            }
        })
    }
    return deviceNameArr
}

// 设备位置
DeviceAPI.devicePos = {
    inside: {
        "buildingOne": {},
        "buildingTwo": {},
        "buildingThree": {},
        "buildingFour": {},
        "buildingFive": {},
        "buildingSix": {},
        "buildingSeven": {
            "floorOne": [],
            "floorTwo": [],
            "floorThree": [],
        },
        "buildingEight": {},
        "buildingNine": {},
        "buildingTen": {},
        "buildingEleven": {}
    },
    outside: []
}

DeviceAPI.createIcon = function (iconName, url) {
    let icon
    if (arguments.length === 4) {
        icon = BABYLON.MeshBuilder.CreatePlane(iconName, {height: arguments[2], width: arguments[3]});
    } else {
        icon = BABYLON.MeshBuilder.CreatePlane(iconName, {height: 4, width: 4});
    }
    let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
    materialPlane.diffuseTexture = new BABYLON.Texture(url, scene)
    // 应用透明度
    materialPlane.opacityTexture = new BABYLON.Texture(url, scene);
    materialPlane.backFaceCulling = true;
    materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
    materialPlane.disableLighting = true;
    icon.material = materialPlane;
    icon.enablePointerMoveEvents = true;
    icon.setEnabled(false);
    icon.billboardMode = 7;
    return icon;
}

DeviceAPI.cloneIcon = function (device, iconPlane) {
    let apiDevice = DeviceAPI.deviceInterfaceData[device.type].find(item => item.DEVICE_ID === device.name)
    if (apiDevice) {
        let iconPlane_ = iconPlane.clone(device.type + "-" + device.name);
        iconPlane_.position = device.pos;
        return iconPlane_
    }
}

DeviceAPI.setIconNew = function () {
    // 教室空闲/不空闲图标
    let classroomUse = this.createIcon("classroomUse", require("../assets/image/icon/classroom.png"));
    let classroomUnused = this.createIcon("classroomUnused", require("../assets/image/icon/classroom_1.png"));
    // 监控图标
    let monitorPlane = this.createIcon("monitorPlane", require("../assets/image/icon/monitor.png"));
    // 光照开启/关闭图标
    let lightPlaneOn = this.createIcon("lightPlaneOn", require("../assets/image/icon/light_on.png"));
    let lightPlaneOff = this.createIcon("lightPlaneOff", require("../assets/image/icon/light_off.png"));
    // 广播开启/关闭图标
    let broadcastPlaneOn = this.createIcon("broadcastPlaneOn", require("../assets/image/icon/broadcast_on.png"));
    let broadcastPlaneOff = this.createIcon("broadcastPlaneOff", require("../assets/image/icon/broadcast_off.png"));
    // 用水量图标
    let waterTankPlane = this.createIcon("useWaterPlane", require("../assets/image/icon/water_use.png"), 2, 8);
    // 用电量图标
    let powerBoxPlane = this.createIcon("useElectricPlane", require("../assets/image/icon/electric_use.png"), 3, 8);
    // co图标
    let coMonitorPlane = this.createIcon("coPlane", require("../assets/image/icon/co.png"), 2, 6.8);
    // 集水坑图标
    let waterMonitorPlane = this.createIcon("waterPlane", require("../assets/image/icon/water.png"), 2, 6);
    // 排风图标
    let fanPlane = this.createIcon("fanPlane", require("../assets/image/icon/fan.png"));
    // 电梯图标
    let liftPlane = this.createIcon("liftPlane", require("../assets/image/icon/lift.png"));
    // 空调图标
    let airPlane = this.createIcon("airPlane", require("../assets/image/icon/elevator.png"));
    // 发电机图标
    let dynamoPlane = this.createIcon("dynamoPlane", require("../assets/image/icon/electric.png"));


    // 室内图标
    for (let building in DeviceAPI.devicePos["inside"]) {
        for (let floor in DeviceAPI.devicePos["inside"][building]) {
            DeviceAPI.devicePos["inside"][building][floor].forEach(device => {
                switch (device.type) {
                    case "manage":
                        device.meshOn = this.cloneIcon(device, classroomUse)
                        device.meshOff = this.cloneIcon(device, classroomUnused)
                        break
                    case "monitor":
                        device.mesh = this.cloneIcon(device, monitorPlane)
                        break
                    case "broadcast":
                        device.meshOn = this.cloneIcon(device, broadcastPlaneOn)
                        device.meshOff = this.cloneIcon(device, broadcastPlaneOff)
                        break
                    case "light":
                        device.meshOn = this.cloneIcon(device, lightPlaneOn)
                        device.meshOff = this.cloneIcon(device, lightPlaneOff)
                        break
                    case "waterTank":
                        device.mesh = this.cloneIcon(device, waterTankPlane)
                        break
                    case "powerBox":
                        device.mesh = this.cloneIcon(device, powerBoxPlane)
                        break
                    case "exhaustFan":
                        device.mesh = this.cloneIcon(device, fanPlane)
                        break
                    case "lift":
                        device.mesh = this.cloneIcon(device, liftPlane)
                        break
                    case "airConditioning":
                        device.mesh = this.cloneIcon(device, airPlane)
                        break
                    case "dynamo":
                        device.mesh = this.cloneIcon(device, dynamoPlane)
                        break
                    default:
                        break
                }
            })
        }
    }

    // 室外图标
    DeviceAPI.devicePos["outside"].forEach(device => {
        switch (device.type) {
            case "monitor":
                device.mesh = this.cloneIcon(device, monitorPlane)
                break
            case "broadcast":
                device.meshOn = this.cloneIcon(device, broadcastPlaneOn)
                device.meshOff = this.cloneIcon(device, broadcastPlaneOff)
                break
            case "light":
                device.meshOn = this.cloneIcon(device, lightPlaneOn)
                device.meshOff = this.cloneIcon(device, lightPlaneOff)
                break
            case "coMonitor":
                device.mesh = this.cloneIcon(device, coMonitorPlane)
                break
            case "waterMonitor":
                device.mesh = this.cloneIcon(device, waterMonitorPlane)
                break
        }
    })
}
export default DeviceAPI